
import { prop, Vue } from "vue-class-component";
class Props {
  modelValue = prop<any[]>({
    required: true
  });
  captionError = prop<string>({
    default: "",
    type: String
  });
  maxSize = prop<number>({
    default: 16,
    type: Number
  });
  maxUpload = prop<number>({
    default: 5,
    type: Number
  });
}
export default class UploadFile extends Vue.with(Props) {
  isDataValidate = false;
  isDragFile = false;
  imageTemps: Array<any> = [];
  fileStores: Array<any> = [];

  $refs!: {
    file: HTMLFormElement;
  };

  setDragAndDrop(e: any, value: boolean) {
    this.isDragFile = value;
    e.preventDefault();
  }

  onUploadImage(file: any) {
    const type = file.name.split(".").pop();

    if (type === "jpg" || type === "png" || type === "jpeg") {
      if (file.size > this.maxSize * 1024 * 1024) this.setError();
      else {
        this.imageTemps.push(URL.createObjectURL(file));
        this.fileStores.push(file);
        this.$emit("update:modelValue", this.fileStores);
        this.isDataValidate = false;
        this.isDragFile = false;
      }
    } else this.setDefault();
  }

  removeImg(index: number) {
    this.imageTemps.splice(index, 1);
    this.fileStores.splice(index, 1);
  }

  onSubmitClick(e: any) {
    if (!e.target.files.length) this.setDefault();

    for (const targetFile of e.target.files) {
      if (this.imageTemps.length < 5) {
        this.onUploadImage(targetFile);
      }
    }
  }

  onSubmitDrag(e: any) {
    if (!e.dataTransfer.files.length) this.setDefault();

    for (const dataTransferFile of e.dataTransfer.files) {
      if (this.imageTemps.length < 5) {
        const file = e.dataTransfer && dataTransferFile;
        this.onUploadImage(file);
      }
    }
  }

  setDefault() {
    this.$emit("update:modelValue", []);
    this.$refs.file.value = "";
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  setError() {
    this.isDataValidate = true;
    this.isDragFile = false;
  }

  resetTempsImage() {
    this.imageTemps = [];
    this.fileStores = [];
    this.isDataValidate = false;
  }
}
